import { PATH_DASHBOARD, PATH_PAGE } from '../../../routes/paths';
import SvgColor from '../../../components/svg-color';

const icon = (name: string) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

export const ICONS = {
  blog: icon('ic_blog'),
  cart: icon('ic_cart'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  aiRobot: icon('robot-artificial-intelligence'),
  aiStart: icon('aiStart'),
  home: icon('ic_home'),
  dot: icon('ic_dot'),
  settings: icon('ic_setting'),
  help: icon('ic_help'),
  coin: icon('ic_coin'),
  portrait: icon('portrait'),
  video: icon('ic_video'),
  stories: icon('ic_stories'),
};

const navConfig = [
  // AI Training
  // ----------------------------------------------------------------------
  {
    // subheader: 'Start Your Journey',
    items: [
      { title: 'menu.home', path: PATH_DASHBOARD.general.app, icon: ICONS.home },
      // { title: 'menu.myStories', path: PATH_DASHBOARD.general.stories, icon: ICONS.stories },
      { title: 'menu.myVideos', path: PATH_DASHBOARD.general.videos, icon: ICONS.video },
      { title: 'menu.myProfile', path: PATH_DASHBOARD.profile.root, icon: ICONS.aiRobot },
    ],
  },

  // Chat
  // ----------------------------------------------------------------------
  {
    subheader: 'menu.chat',
    items: [],
  },

  // Discover
  // ----------------------------------------------------------------------
  {
    subheader: 'menu.discover',
    items: [],
  },
  // Settings
  // ----------------------------------------------------------------------
  {
    subHeader: ' ',
    items: [
      { title: 'menu.getCoins', path: PATH_DASHBOARD.general.purchaseOptions, icon: ICONS.coin },
      { title: 'menu.settings', path: PATH_DASHBOARD.settings.root, icon: ICONS.settings },
      { title: 'menu.help', path: PATH_PAGE.faqs, icon: ICONS.help },
    ],
  },
];

const navConfigChat = [
  // AI Training
  // ----------------------------------------------------------------------
  {
    // subheader: 'Start Your Journey',
    items: [
      { title: 'menu.home', path: PATH_DASHBOARD.general.app, icon: ICONS.home },
      { title: 'menu.myProfile', path: PATH_DASHBOARD.profile.root, icon: ICONS.aiRobot },
    ],
  },

  // Chat
  // ----------------------------------------------------------------------
  {
    subheader: 'menu.chat',
    items: [],
  },
  // Settings
  // ----------------------------------------------------------------------
  {
    subHeader: ' ',
    items: [
      { title: 'menu.getCoins', path: PATH_DASHBOARD.general.purchaseOptions, icon: ICONS.coin },
      { title: 'menu.settings', path: PATH_DASHBOARD.settings.root, icon: ICONS.settings },
      { title: 'menu.help', path: PATH_PAGE.faqs, icon: ICONS.help },
    ],
  },
];

export { navConfig, navConfigChat };
