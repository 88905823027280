import { Box, IconButton, List, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useLocales } from '../../../locales';
import { StyledSubheader } from './styles';
import NavList from './NavList';
import NavListOnClick from './NavListOnClick';

import { useEffect, useLayoutEffect, useRef, useState, useCallback } from 'react';
import SvgColor from '@/components/svg-color';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useStateDataRequest } from '@/src/communication/socket/dataRequest';
import { SocketEmits } from '@api-types';
import { navConfig, navConfigChat } from '@/layouts/dashboard/nav/config-navigation';

import { PATH_DASHBOARD } from '@/routes/paths';
import { atomWithStorage } from 'jotai/utils';
import AppConfig from '@/config/config';
import ChooseCharacterDropDown from '@/sections/dashboard/app/ChooseCharacterDropDown';
import {
  newChatModeSelectorAtom,
  NewConversationMode,
  newConversationTriggerAtom,
  showVerticalChatMenuAtom,
} from '@/sections/dashboard/chatInterface/Chat.states';
import ConversationsList from '@/sections/dashboard/chatInterface/ConversationsList';
import Iconify from '@/components/iconify';
import { selectedPictureSetAtom } from '@/src/atoms/upload.states';
import { AppActiveSection, appActiveSectionModeAtom } from '@/src/atoms/useApp.states';
import { useRouter } from 'next/router';
interface NavSectionVerticalProps {
  sx?: any;
  data?: any;
}

const StyledList = styled(List)(({ theme }) => ({
  px: 0.5,
  '&::after': {
    content: '""',
    display: 'block',
    height: 1,
    boxShadow: `inset 0 -0.2px 0 0 ${theme.palette.common.white}`,
    marginBottom: theme.spacing(1.5),
    marginTop: theme.spacing(1.5),
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
  },
}));
type NavigationData = { category: string; mainCategory: string; icon?: string }[];
const initData = atomWithStorage<NavigationData | null>('navigationData', null);
export const portraitSetsMainCategoryAtom = atomWithStorage<string>('mainCategory', 'photoshooting');
export const prevMainCategoryAtom = atomWithStorage<string>('prevMainCategory', '');

export default function NavSectionVertical({ sx, ...other }: NavSectionVerticalProps) {
  const { translate } = useLocales();
  const [showMore, setShowMore] = useState(true);
  const [globalData, setGlobalData] = useAtom(initData);
  const mainCategory = useAtomValue(portraitSetsMainCategoryAtom);
  const navigationConfigRef = useRef(navConfig);
  const [isDataReady, setIsDataReady] = useState(false);
  const [filteredItems, setFilteredItems] = useState<any[]>([]);
  const [showVerticalChatMenu, setShowVerticalChatMenu] = useAtom(showVerticalChatMenuAtom);

  const icon = (name: string) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;
  useStateDataRequest(SocketEmits.GetCategories, {}, setGlobalData);

  const selectedPictureSet = useAtomValue(selectedPictureSetAtom);
  const setActiveSectionMode = useSetAtom(appActiveSectionModeAtom);
  const setShowNewChatModeSelector = useSetAtom(newChatModeSelectorAtom);

  useEffect(() => {
    if (globalData && globalData.length > 0 && mainCategory) {
      const newFilteredItems = globalData
        // .filter((category) => category.mainCategory === mainCategory)
        .filter((category) => category.category !== 'story')
        .map((category) => ({
          title: category.category,
          mainCategory: category.mainCategory,
          path: `${PATH_DASHBOARD.general.app}?discover=${encodeURIComponent(category.category)}`,
          onClick: () => {
            setActiveSectionMode(AppActiveSection.Portraits);
          },
          icon: category.icon ? icon(category.icon) : icon('ic_blank'),
          img: category.category.startsWith('categories.')
            ? `${AppConfig.publicCategoryImages}/${category.category.replace('categories.', '')}.jpg`
            : undefined,
        }))
        .sort((a, b) => {
          const translatedTitleA = translate(a.title);
          const translatedTitleB = translate(b.title);
          return translatedTitleA.localeCompare(translatedTitleB);
        });

      setFilteredItems(newFilteredItems);
      setIsDataReady(true);
    }
  }, [globalData, mainCategory, showVerticalChatMenu]);

  const handleClick = () => {
    setShowMore(!showMore);
  };

  // Nav auto expand
  const containerRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    // If the ref is not attached to an element, do nothing.
    if (!containerRef.current) {
      return;
    }

    // Get the height of the container.
    const containerHeight = containerRef.current.offsetHeight;
    if (containerHeight === 0) {
      return;
    }

    if (containerHeight < window.innerHeight - 300) {
      // If the height of the container is less than the height of the viewport, show all items.
      setShowMore(true);
    } else {
      setShowMore(false);
    }
  }, []);

  const router = useRouter();
  const setNewConversationTrigger = useSetAtom(newConversationTriggerAtom);
  const handleNewConversation = useCallback(async () => {
    {
      router.push(PATH_DASHBOARD.general.chat, undefined, { shallow: true });
      setShowNewChatModeSelector(true);

      // setNewConversationTrigger(NewConversationMode.InitNewChat);
    }
  }, [router]);

  return (
    <>
      {isDataReady && ( // Render only when data is ready
        <Stack sx={sx} {...other} ref={containerRef}>
          <ChooseCharacterDropDown />
          {navigationConfigRef.current.map((group: { subheader?: string; subHeader?: string; items: any[] }, index: number) => {
            const showMaxEntries = 5;
            const key = group.subheader || group.items[0].title;
            const items = index === 2 ? (showMore ? filteredItems : filteredItems.slice(0, showMaxEntries)) : group.items;

            return (
              <StyledList key={key} disablePadding sx={{ mt: 2 }}>
                {group.subheader && (
                  <StyledSubheader disableSticky index={index} sx={{ display: 'flex', alignItems: 'space-between', width: '100%' }}>
                    <Box sx={{ flex: 1 }}>{translate(group.subheader)}</Box>
                    {group.subheader === 'menu.chat' && selectedPictureSet && (
                      <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
                        <IconButton onClick={handleNewConversation}>
                          <Iconify icon="jam:write-f" color="white" />
                        </IconButton>
                      </Box>
                    )}
                  </StyledSubheader>
                )}

                {/* Render ConversationsList if the subheader is 'menu.chat' */}
                {group.subheader === 'menu.chat' && (
                  <li>
                    <ConversationsList />
                  </li>
                )}
                {items.map((list) => (
                  <NavList key={list.title + list.path} data={list} depth={1} hasChild={!!list.children} />
                ))}

                {index === 2 && filteredItems.length > showMaxEntries && (
                  <NavListOnClick
                    key={showMore ? 'showLess' : 'showMore'}
                    data={{
                      title: translate(showMore ? 'menu.showLess' : 'menu.showMore'),
                      icon: icon(showMore ? 'arrow-up-s-line' : 'arrow-down-s-line'),
                      path: '###',
                    }}
                    depth={1}
                    hasChild={false}
                    onClicking={handleClick}
                  />
                )}
              </StyledList>
            );
          })}
        </Stack>
      )}
    </>
  );
}
