import React from 'react';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import {
  readyPictureSetsAtom,
  selectedFoundationPictureAtom,
  selectedPictureSetAtom,
  selectedPreviewGenderAtom,
} from '@/src/atoms/upload.states';
import { Select, MenuItem, FormControl, InputLabel, SelectChangeEvent, useTheme } from '@mui/material';
import useLocales from '@/locales/useLocales';
import { PATH_DASHBOARD } from '@/routes/paths';
import { useRouterQuery } from '@/hooks/useRouterQuery';
import { useRouter } from 'next/router';
import AddIcon from '@mui/icons-material/Add'; // Import AddIcon
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'; // Import FiberManualRecordIcon
import {
  messagesAtom,
  currentConversationIdAtom,
  localMessagesAtom,
  reloadConversationsAtom,
  conversationsAtom,
} from '../chatInterface/Chat.states';

// Define an array of allowed paths
const allowedPaths = [PATH_DASHBOARD.general.portraits, PATH_DASHBOARD.general.app];

const ChooseCharacterDropDown = () => {
  const { translate } = useLocales();
  const theme = useTheme();
  const readyPictureSets = useAtomValue(readyPictureSetsAtom);
  const [selectedPictureSet, setSelectedPictureSet] = useAtom(selectedPictureSetAtom);
  const setSelectedPreviewGender = useSetAtom(selectedPreviewGenderAtom);
  const { pathname } = useRouterQuery();
  const router = useRouter(); // Initialize router

  // Ensure selectedPictureSet has a default value
  const defaultSelectedSet = readyPictureSets.length > 0 ? readyPictureSets[0] : { id: 0, name: '' };
  const currentSelectedSet = selectedPictureSet || defaultSelectedSet;
  const setSelectedFoundationPicture = useSetAtom(selectedFoundationPictureAtom);
  const setReloadConversations = useSetAtom(reloadConversationsAtom);
  const setCurrentConversationId = useSetAtom(currentConversationIdAtom);
  const setMessages = useSetAtom(messagesAtom);
  const setLocalMessages = useSetAtom(localMessagesAtom);
  const setConversations = useSetAtom(conversationsAtom);
  const handleSelectChange = (event: SelectChangeEvent<number>) => {
    const selectedId = event.target.value as number;
    if (selectedId === 0) {
      // Assuming 0 is the value for "uploadNewProfile"
      router.push(PATH_DASHBOARD.profile.root, undefined, { shallow: true });
    } else {
      const selectedSet = readyPictureSets.find((set) => set?.id === selectedId);
      if (selectedSet) {
        setSelectedPictureSet(selectedSet);
        setSelectedPreviewGender(selectedSet.gender);
        setSelectedFoundationPicture(null);
        setCurrentConversationId(null);
        setMessages([]);
        setLocalMessages([]);
        setConversations([]);
        setReloadConversations((prev) => !prev);
      } else {
        setSelectedPreviewGender(null);
      }
    }
  };

  // Check if the current path is in the allowedPaths array
  if (!allowedPaths.includes(pathname)) {
    // return null;
  }

  if (!currentSelectedSet) {
    return null;
  }

  if (readyPictureSets.length <= 1) {
    return null;
  }

  return (
    <FormControl variant="filled" size="small" sx={{ minWidth: 120, border: 'none', width: '100%' }}>
      <InputLabel id="picture-set-select-label">{translate('portraitDropDown.chooseYourCharacter')}</InputLabel>
      <Select
        labelId="picture-set-select-label"
        id="picture-set-select"
        value={currentSelectedSet.id}
        onChange={handleSelectChange}
        label={translate('portraitDropDown.chooseYourCharacter')}
        renderValue={(selected) => {
          const selectedSet = readyPictureSets.find((set) => set?.id === selected);
          return selectedSet ? selectedSet.name : '';
        }}
      >
        {readyPictureSets.map(
          (set) =>
            set && (
              <MenuItem key={set.id} value={set.id}>
                <FiberManualRecordIcon style={{ marginRight: theme.spacing(1.5), fontSize: 'small', marginLeft: theme.spacing(0.7) }} />
                {set.name}
              </MenuItem>
            )
        )}
        <MenuItem value={0}>
          <AddIcon style={{ marginRight: theme.spacing(1) }} />
          {translate('portraitDropDown.uploadNewProfile')}
        </MenuItem>
      </Select>
    </FormControl>
  );
};

export default ChooseCharacterDropDown;
